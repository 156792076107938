<template>
  <AccordionBase
    ref="accordion"
    :name="name"
    :drawers="computedDrawers"
    :initial-open="initialOpen"
    :is-multi-open="isMultiOpen"
    @drawer-open="handleDrawerOpen"
    @drawer-close="handleDrawerClose">
    <template
      v-slot:label="{ drawer, isOpen }">
      <div
        class="__face">
        {{ drawer.label }}

        <TooltipBase
          class="__tooltip"
          v-if="drawer.labelTooltip">
          {{ drawer.labelTooltip}}
        </TooltipBase>

        <div
          class="font-normal pt-half"
          v-if="isShowingDrawerLabelAnswer(drawer.model, isOpen)">
          {{ computedDrawerLabelAnswer({ drawer, isModelOther: false }) }}
        </div>

        <div
          class="font-normal pt-half"
          v-if="drawer.isOtherable && isShowingDrawerLabelAnswer(drawer.modelOther, isOpen)">
          {{ computedDrawerLabelAnswer({ drawer, isModelOther: true }) }}
        </div>

        <ErrorMessage
          class="mt-half"
          v-if="drawer.errorMessage && hasSubmitted && !session[drawer.model].isValidClientSide"
          :icon="null">
          {{ drawer.errorMessage }}
        </ErrorMessage>

        <IconCheckbubble
          class="__icon"
          size="24"
          color="#99CCEE"
          :is-invalid="hasSubmitted && !session[drawer.model].isValidClientSide"
          :is-checked="session[drawer.model].isValidClientSide"
        />
      </div>
    </template>

    <template
      v-slot:content="{ drawer, drawerIndex }">

      <!-- SELECT -->
      <InputSelect
        v-if="drawer.inputType === 'select'"
        :class="`InputSelect-theme-${drawer.inputSelectTheme || 'default'}`"
        v-model="session[drawer.model].value"
        v-bind="drawer.props"
        @select="handleSelect(drawer, drawerIndex)">
        <template
          v-slot:tile="{ option, isSelected }">
          <div
            class="__tile"
            :class="{ isSelected }">
            <IconBase
              class="__icon"
              v-if="['icon'].includes(drawer.inputSelectTheme)"
              :name="option.icon"
            />

            <IconRadio
              class="__radio"
              v-if="['radio'].includes(drawer.inputSelectTheme)"
              size="24"
              color="#99CCEE"
              :is-checked="isSelected"
            />

            <span
              class="__label"
              v-html="option.label">
            </span>
          </div>
        </template>
      </InputSelect>

      <!-- TEXT -->
      <InputText
        v-if="drawer.inputType === 'text'"
        :ref="drawer.model"
        v-model="session[drawer.model].value"
        v-bind="drawer.props"
        :is-invalid="!session[drawer.model].isValidClientSide"
        @keyup="(e) => handleTextKeyup(drawer, e)"
      />

      <!-- OTHERABLE -->
      <InputSelectOptionOther
        v-if="drawer.isOtherable"
        :model-value="session[drawer.modelOther].value"
        v-bind="drawer.propsOther"
        :is-invalid="!session[drawer.modelOther].isValidClientSide"
        :ref="drawer.modelOther"
        @submit="handleSubmitOther(drawer, drawerIndex)"
        @update:model-value="handleOtherInput(drawer, $event)"
      />

    </template>
  </AccordionBase>
</template>


<script>
import AccordionBase from '@/components/AccordionBase.vue';
import TooltipBase from '@/components/TooltipBase.vue';
import InputSelect from '@/components/InputSelect.vue';
import InputText from '@/components/InputText.vue';
import InputSelectOptionOther from '@/components/InputSelectOptionOther.vue';
import IconRadio from '@/components/IconRadio.vue';
import IconCheckbubble from '@/components/IconCheckbubble.vue';
import IconBase from '@/components/IconBase.vue';
import ErrorMessage from '@/components/ErrorMessage.vue';

export default {
  name: 'AccordionForm',

  components: {
    AccordionBase,
    TooltipBase,
    InputSelect,
    InputText,
    InputSelectOptionOther,
    IconRadio,
    IconCheckbubble,
    IconBase,
    ErrorMessage,
  },

  inject: [
    'session',
  ],

  props: {
    name: {
      type: String,
      required: true,
    },
    drawers: {
      type: Array,
      required: true,
    },
    isMultiOpen: {
      type: Boolean,
      default: false,
    },
    initialOpen: {
      type: [Number, Array],
      default: null,
    },
    hasSubmitted: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    computedDrawers() {
      return this.drawers.filter((drawer) => !!this.session[drawer.model]);
    },
  },

  methods: {
    handleSubmitOther(_drawer, drawerIndex) {
      this.$refs.accordion.closeDrawer(drawerIndex);
      this.maybeOpenNextDrawer(drawerIndex);
    },

    handleTextKeyup(drawer, e) {
      if (
        e.key === 'Enter'
        && drawer.onEnter
        && typeof drawer.onEnter === 'function'
      ) {
        drawer.onEnter();
      }
    },

    handleSelect(drawer, drawerIndex) {
      if (drawer.onSelect) drawer.onSelect({ drawer });

      if (drawer.isOtherable) {
        this.session[drawer.modelOther].value = this.session[drawer.modelOther].defaultValue;
        this.$refs[drawer.modelOther].close();
      }

      if (drawer.props.isMultiSelect) return;

      this.$refs.accordion.closeDrawer(drawerIndex);
      this.maybeOpenNextDrawer(drawerIndex);
    },

    maybeOpenNextDrawer(currentDrawerIndex) {
      const nextDrawer = this.computedDrawers[currentDrawerIndex + 1] || null;

      if (nextDrawer) {
        this.$refs.accordion.openDrawer(currentDrawerIndex + 1);
      }
    },

    handleOtherInput(drawer, value) {
      this.session[drawer.model].value = this.session[drawer.model].defaultValue;
      this.session[drawer.modelOther].value = value;
    },

    handleDrawerOpen(drawer) {
      if (drawer.inputType === 'text') {
        setTimeout(() => this.$refs[drawer.model].focus(), 100);
      }

      if (drawer.isOtherable && this.session[drawer.modelOther].value) {
        setTimeout(() => this.$refs[drawer.modelOther].focus(), 100);
      }
    },

    handleDrawerClose(drawer) {
      if (drawer.isOtherable && !this.session[drawer.modelOther].value) {
        this.$refs[drawer.modelOther].close();
      }
    },

    isShowingDrawerLabelAnswer(drawerModel, isOpen) {
      if (isOpen) return false;

      const drawerValue = this.session[drawerModel].value;

      if (typeof drawerValue === 'boolean') return true;

      return !!drawerValue;
    },

    computedBoolAnswer(bool) {
      return bool ? 'Yes' : 'No';
    },

    computedDrawerLabelAnswer({ drawer, isModelOther }) {
      const drawerValue = isModelOther
        ? this.session[drawer.modelOther].value
        : this.session[drawer.model].value;

      const defaultLabelValue = (typeof drawerValue === 'boolean')
        ? this.computedBoolAnswer(drawerValue)
        : drawerValue;

      return drawer.customDrawerLabelAnswer
        ? drawer.customDrawerLabelAnswer({ drawer })
        : defaultLabelValue;
    },
  },
};
</script>


<style lang="scss" scoped>
@import '@/styles/_variables';

.__face {
  position: relative;
  padding: 8px 40px 8px 0;
  text-align: left;
  font-size: 16px;
  font-weight: $font-weight-bold;
  .__tooltip {
    color: $dark;
    font-size: 12px;
    font-weight: 400;
  }
  .__icon {
    position: absolute;
    top: 8px;
    right: 0;
  }
}
</style>
