<template>
  <div
    class="AccordionBase"
    :aria-label="`${name} control group buttons`">

    <AccordionDrawer
      v-for="(drawer, drawerIndex) in drawers"
      :is-open="isDrawerOpen(drawerIndex)"
      :name="`${name}-content-${drawerIndex}`"
      :key="`accordion-${name}-drawer-${drawerIndex}`"
      @click="toggleDrawer(drawerIndex)">
      <template
        v-slot:label>
        <slot
          name="label"
          :drawer="drawer"
          :drawer-index="drawerIndex"
          :is-open="isDrawerOpen(drawerIndex)">
        </slot>
      </template>

      <template
        v-slot:content>
        <slot
          name="content"
          :drawer="drawer"
          :drawer-index="drawerIndex"
          :is-open="isDrawerOpen(drawerIndex)">
        </slot>
      </template>
    </AccordionDrawer>

  </div>
</template>


<script>
import AccordionDrawer from '@/components/AccordionDrawer.vue';

export default {
  name: 'AccordionBase',

  components: {
    AccordionDrawer,
  },

  props: {
    name: {
      type: String,
      required: true,
    },
    drawers: {
      type: Array,
      required: true,
    },
    isMultiOpen: {
      type: Boolean,
      default: false,
    },
    initialOpen: {
      type: [Number, Array],
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    'drawer-open',
    'drawer-close',
  ],

  data() {
    return {
      openDrawerIndex: this.getInitialOpen(),
    };
  },
  methods: {
    getInitialOpen() {
      if (this.initialOpen === null) {
        return this.isMultiOpen
          ? []
          : null;
      }

      if (this.isMultiOpen) {
        return (this.initialOpen && typeof this.initialOpen === 'object')
          ? [...this.initialOpen]
          : [0];
      }

      return (typeof this.initialOpen === 'number')
        ? this.initialOpen
        : 0;
    },

    isDrawerOpen(drawerIndex) {
      return this.isMultiOpen
        ? this.openDrawerIndex.includes(drawerIndex)
        : this.openDrawerIndex === drawerIndex;
    },

    openDrawer(drawerIndex) {
      if (this.isDisabled) return;

      this.openDrawerIndex = this.isMultiOpen
        ? [...this.openDrawerIndex.filter((oi) => oi !== drawerIndex), drawerIndex]
        : drawerIndex;

      this.$emit('drawer-open', this.drawers[drawerIndex]);
    },

    closeDrawer(drawerIndex) {
      if (this.isDisabled) return;

      this.openDrawerIndex = this.isMultiOpen
        ? this.openDrawerIndex.filter((openIndex) => openIndex !== drawerIndex)
        : null;

      this.$emit('drawer-close', this.drawers[drawerIndex]);
    },

    toggleDrawer(drawerIndex) {
      this[`${this.isDrawerOpen(drawerIndex) ? 'close' : 'open'}Drawer`](drawerIndex);
    },

    closeAll() {
      if (this.isDisabled) return;

      this.openDrawerIndex = this.isMultiOpen
        ? []
        : null;
    },
  },
};
</script>


<style lang="scss" scoped>
@import '@/styles/_variables';
</style>
