<template>
  <div
    class="AccordionDrawer"
    :class="{ isOpen }">
    <button
      class="__label"
      type="button"
      :aria-controls="name"
      :aria-expanded="isOpen"
      @click="handleClick">
      <slot
        name="label">
        Label
      </slot>
    </button>

    <div
      class="__content"
      :id="name"
      :aria-hidden="!isOpen"
      v-show="isOpen">
      <slot
        name="content">
        Content
      </slot>
    </div>
  </div>
</template>


<script>
export default {
  name: 'AccordionDrawer',

  emits: [
    'click',
  ],

  props: {
    name: {
      type: String,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    handleClick() {
      this.$emit('click');
    },
  },
};
</script>


<style lang="scss" scoped>
@import '@/styles/_variables';

.AccordionDrawer {
  border: $border;
  border-radius: $radius;
}

.AccordionDrawer + .AccordionDrawer {
  margin-top: 16px;
}

.__label {
  display: block;
  width: 100%;
  padding: 12px 16px;
  overflow: visible;
}

.__content {
  padding: 0 16px 24px 16px;
}
</style>
