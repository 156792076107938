<template>
  <div
    class="IconCheckbubble"
    :class="{ isChecked, isInvalid }"
    :style="computedRadioStyle">
    <IconCheckmark
      class="__checkmark"
      :size="size * 0.75"
      :is-checked="isChecked"
    />
  </div>
</template>


<script>
import IconCheckmark from '@/components/IconCheckmark.vue';

export default {
  name: 'IconCheckbubble',

  components: {
    IconCheckmark,
  },

  props: {
    isChecked: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    size: {
      type: [String, Number],
      default: 18,
    },
    color: {
      type: String,
      default: 'currentColor',
    },
  },

  computed: {
    computedRadioStyle() {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
        backgroundColor: this.color,
        borderColor: this.color,
      };
    },
  },
};
</script>


<style lang="scss" scoped>
@import '@/styles/_variables';

.IconCheckbubble {
  position: relative;
  border-radius: 50%;
  border: $border;
}

.__checkmark {
  @include absolute;
  color: $white;
}

.IconCheckbubble:not(.isChecked) {
  background-color: transparent !important;
  border-color: $border-color !important;
}

.IconCheckbubble.isInvalid {
  border-color: $red !important;
}
</style>
